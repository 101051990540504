import {Storage} from "@/services/Storage"
import axios from "axios"
import * as StompJs from "@stomp/stompjs";

export default {
    name: 'user_security',
    state: {
        userSecurity: undefined,
        isUserSecurityOpen: false,
        securityWebsocket: null,
        securityIsConnected: false,
    },
    getters: {
        getUserSecurity: (state) => state.userSecurity,
        isConnectedSecurity: (state) => state.securityIsConnected,
        isUserSecurityOpen: (state) => state.isUserSecurityOpen
    },
    mutations: {
        setUserSecurity: (state, data) => state.userSecurity = data,
        setUserSecurityOpen: (state, data) => state.isUserSecurityOpen = data,
        SET_SECURITY_WEBSOCKET(state, websocket) {
            state.securityWebsocket = websocket;
        },
        SET_SECURITY_CONNECTED(state, status) {
            state.securityIsConnected = status;
        },
    },
    actions: {
        disconnectSecuritiesWebSocket({ commit, state }) {
            if (state.securityWebsocket) {
                state.securityWebsocket.close();
                commit("SET_SECURITY_WEBSOCKET", null);
                commit("SET_SECURITY_CONNECTED", false);
            }
        },
        connectWebSocket:async (context,id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (context.state.securityIsConnected || !storeDB) return;
            let brokerURL = securityApiWebsocket;
            const stompClient = new StompJs.Client({
                brokerURL,
                reconnectDelay: 5000, // Reconnect every 5 seconds if disconnected
                onConnect: (frame) => {
                    console.log('Connected: ' + frame);
                    context.commit('SET_SECURITY_CONNECTED', true);

                    // Subscribe to a topic
                    stompClient.subscribe('/topic/approvedRequest', ({body}) => {
                        console.trace({body})
                        responses.showSuccess(body);
                    });
                },
                onStompError: (frame) => {
                    console.error('Broker error:', frame.headers['message']);
                    console.error('Details:', frame.body);
                },
                onWebSocketError: (error) => {
                    console.error('WebSocket error:', error);
                },
            });

            stompClient.activate(); // Start the connection
        },
        checkUserSecurity: async (context, security) => {
            return new Promise(async (resolve, reject) => {
                let id = generateUUID();
                // let image = await screenShot()
                let user = await Storage.getCurrentUser();
                let payload = {
                    id: id,
                    //   base64image: image,
                    requestedUser: user.Username,
                    Type: security,
                    promise: {
                        resolve: (value)=>resolve(value),
                        reject: (error)=>reject(error)
                    }
                }
                context.commit('setUserSecurity', payload)
                context.commit('setUserSecurityOpen', true)
            })
                .then((result)=> {
                    return {isConfirmed:true,value:result}
                })
        },
        requestSecurityOverride: async (context,payload) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.post(`${backOfficeTomcatApi}supervisor/${store.StoreDB}/createRequest`, payload)
                .then(({data}) => data)
        },
        cancelSecurityOverrideRequest: async (context,id) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.post(`${backOfficeTomcatApi}supervisor/${store.StoreDB}/cancelRequest/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
                .finally(()=>{
                    context.commit('setUserSecurityOpen', false)
                    context.commit('setUserSecurity', undefined)
                })
        },
        checkSecurityOverrideRequest: async (context,id) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(`${backOfficeTomcatApi}supervisor/${store.StoreDB}/checkStatus/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
