import Dexie from 'dexie';

// Initialize Dexie database
export const db = new Dexie('RetailPOS');

// Define metadata table to track schemas
db.version(1).stores({
    stock: '++id, Department, Description, Description2, FastLine, GP, InclCost, PLU, QTY, SellingIncl, SubDep, TaxLabels, archived, barcode, hasImage, id, price1, price2, price3, price4, price5',
    stockSetting: '++id,NonStock, askForBoth, askForDescription, askForPrice, cookingLink, mtv, plu, tourism',
    stock_images: '++id, PLU, Image'
});
