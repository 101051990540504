<template>
  <page-with-back-btn title="LayBy Payment">
    <div class="card mx-3 mb-2">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-2">
              <label class="fw-bolder" for="layby-customer">Customer</label>
              <div class="d-flex flex-row">
                <v-select class="w-100"
                          id="layby-customer"
                          v-model="layByDetails.customerID"
                          :options="layByCustomers"
                          :reduce="customer => customer.id"
                          @search="searchLayByCustomers"
                          label="name"
                ></v-select>
                <a class="btn btn-primary btn-sm mx-2" @click="createNew">
                  <font-awesome-icon icon="add"/>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="fw-bold" for="layby-amount">Initial Amount Paid</label>
            <input id="layby-amount" type="number" step="0.01" class="form-control"
                   v-model.number="layByDetails.amount" :max="this.totalPayment">
          </div>
        </div>

        <line-separator title="Payment Methods"/>

        <div class="row mt-2">
          <div class="col-md-3 d-flex justify-content-center">
            <a @click="processSale(`Cash`)" class="w-75 btn btn-outline-primary">Cash</a>
          </div>
          <div class="col-md-3 d-flex justify-content-center">
            <a @click="processSale(`Card`)" class="w-75 btn btn-outline-info">Card</a>
          </div>
          <div class="col-md-3 d-flex justify-content-center">
            <a @click="processSale(`Kazang`)" class="w-75 btn btn-outline-success">Kazang</a>
          </div>
<!--          <div class="col-md-3 d-flex justify-content-center">-->
<!--            <a @click="processSale(`Spenn`)" class="w-75 btn btn-outline-secondary">Spenn</a>-->
<!--          </div>-->
        </div>
        <div class="row mt-2">
          <div class="col-md-3 d-flex justify-content-center" v-for="method in paymentMethods">
            <ForeignCurrencyLayby :row-data="method" v-if="!method.IsBaseCurrency" @click="processSale(method.name)"/>
            <a @click="processSale(method.name)" v-else class="w-75 btn btn-outline-primary">{{ method.name }}
              ({{ method.CurrencySymbol }})</a>
          </div>
        </div>
      </div>
    </div>

    <custom-modal ref="laybyCreate" title="Create Lay By Customer" :is-form="true" @submit="createLayByCustomer">
      <div class="row mb-2">
        <div class="col-md-6">
          <label class="fw-bold" for="layby-customer-name">Name</label>
          <input id="layby-customer-name" type="text" required class="form-control" v-model="customer.name">
        </div>

        <div class="col-md-6">
          <label class="fw-bold" for="layby-customer-surname">Surname</label>
          <input id="layby-customer-surname" type="text" class="form-control" v-model="customer.surname">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-6">
          <label class="fw-bold" for="layby-customer-contact">Contact No.</label>
          <input id="layby-customer-contact" type="text" required class="form-control" v-model="customer.contact">
        </div>

        <div class="col-md-6">
          <label class="fw-bold" for="layby-customer-tpin">TPIN</label>
          <input id="layby-customer-tpin" type="text" class="form-control" v-model="customer.tpin">
        </div>
      </div>

      <label for="layby-address" class="fw-bold">Address</label>
      <textarea v-model="customer.address" class="form-control" id="layby-address" rows="3"></textarea>

      <line-separator title="Link to Debtor (Optional)"/>
      <label for="layBy_debtor">Debtor</label>
      <v-select id="layBy_debtor"
                v-model="customer.debtorID"
                :options="$store.getters.getDebtors"
                :reduce="debtor=>debtor.Id"
                label="Name"
                @search="searchDebtors"
      ></v-select>
    </custom-modal>
  </page-with-back-btn>
</template>

<script>
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn";
import CustomModal from "@/components/layout/utils/CustomModal";
import LineSeparator from "@/components/layout/utils/LineSeparator";
import ForeignCurrencyLayby from "@/components/point-of-sale/sales/payment/ForeignCurrencyLayby";

export default {
  name: "LayByPayment",
  components: {ForeignCurrencyLayby, LineSeparator, CustomModal, PageWithBackBtn},
  beforeMount() {
    this.$store.commit(`generateSaleUUID`);
  },
  computed: {
    paymentMethods() {
      return this.$store.getters.getPaymentMethods
          .filter(method => method.active);
    },
    totalPayment(){
      return this.$store.getters.getSaleTotal - this.$store.getters.getSaleDiscount.total;
    },
  },
  data() {
    return {
      layByCustomers: [],
      customer: {
        name: null,
        surname: null,
        contact: null,
        tpin: null,
        debtorID: null,
        address: ``,
      },
      layByDetails: {
        customerID: null,
        amount: this.totalPayment,
      }
    }
  },
  methods: {
    searchLayByCustomers(input, loading) {
      loading(true)
      let obj = {name: input};
      return this.$store.dispatch(`searchLayByCustomers`, obj)
          .then(res => this.layByCustomers = res)
          .finally(() => loading(false));
    },
    createNew() {
      return this.$refs.laybyCreate.openModal()
    },
    createLayByCustomer() {
      this.$utils.showLoading();
      return this.$store.dispatch(`createLayByCustomer`, this.customer)
          .then(() => responses.showSuccess(`Customer created successfully`))
          .finally(() => this.$utils.hideLoading())
    },
    searchDebtors(input, loading) {
      loading(true);
      return this.$store.dispatch(`getAllDebtors`, {
        Name: input,
        tpin: "",
        contact: ""
      }).finally(() => loading(false))
    },
    async processSale(paymentMethod) {
      if (!isValidString(this.layByDetails.customerID)) return responses.showWarning(`Please select a Lay By Customer`)
      if (this.layByDetails.amount > this.totalPayment) return responses.showWarning(`Please enter a payment amount less than the sale total`);
      if (paymentMethod === `Kazang`) return this.processKazangPayment();
      let saleDetails = this.$store.getters.getSaleDetails;
      saleDetails.type = paymentMethod;
      this.$store.commit(`setCustomer`,this.layByDetails.customerID)
      let saleData = await this.$store.dispatch('parseInvoice', saleDetails);
      saleData.paidAmount = this.layByDetails.amount;

      this.$utils.showLoading();
      return this.$store.dispatch(`processLayByPayment`,saleData)
          .then(()=>{
            this.$utils.hideLoading();
            return Swal.fire({
              icon: `success`,
              text: `LayBy Completed Successfully`
            }).then(() => this.$router.push({name: 'pos'}));
          }).finally(()=>this.$utils.hideLoading())
    },
    processKazangPayment() {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          return this.$store.dispatch('firstKazangPaymentStepLayBy', input)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return Swal.fire({
            icon: `success`,
            text: `LayBy Completed Successfully`
          }).then(() => this.$router.push({name: 'pos'}));
        }
      })
    }

  }
}
</script>

<style scoped>

</style>