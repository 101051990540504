<template>
  <div class="container-fluid">
    <div class="row">
      <div class="stock-search-bar">
        <button class="barcode-scanner text-white" @click="openTables">
          <font-awesome-icon icon="fa-solid fa-table"/>
        </button>
        <font-awesome-icon class="search-icon position-absolute" icon="fa-solid fa-magnifying-glass"/>

<!--        <input v-model="input" @keyup.enter="onBarcodeScanned(input)"-->
<!--               class="ml-2 stock-search-field form-control"-->
<!--               placeholder="PLU, Barcode,Description" type="text" ref="inputField" >-->

        <input v-model="input" @keyup.enter="onBarcodeScanned(input)"
               class="ml-2 stock-search-field form-control"
               placeholder="PLU, Barcode,Description" type="text" ref="inputField" >

        <button @click="onBarcodeScanned(input)" type="button" class="barcode-scanner mx2 text-white">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
        </button>

        <button @click="open" class="barcode-scanner viewOrder text-white">
          <span class="badge" v-if="saleUnits>0">{{ saleUnits }}</span>
          <font-awesome-icon icon="fa-solid fa-cart-shopping"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StockSearchBar",
  components: {},
  computed: {
    saleUnits() {
      return this.$store.getters.getSaleUnits
    },
    category() {
      return this.$store.getters.getCurrentCategory
    },
    subCategory() {
      return this.$store.getters.getSubCategory
    },
    sub2Category() {
      return this.$store.getters.getSubCategory2
    },
    isTabOpen() {
      return this.$store.getters.getIsTabOpen
    },
    systemSettings() {
      return this.$store.getters.getSystemSettings;
    },

  },
  data() {
    return {
      input: '',
    }
  },
  methods: {
    open() {
      this.$router.push({name: 'viewSale'})
    },
    search(e) {
      this.getStock()
    },
    openTables() {
      return this.$router.push({name: 'tables'})
    },
    addScaleItem(itemObj) {
      this.$store.dispatch('getStockSetting', itemObj.PLU).then(async (set) => {
        if (set.cookingLink) {
          this.$router.push({name: 'instruction', params: {plu: itemObj.PLU}})
          return;
        }
        let price = itemObj.SellingIncl;
        let specialPrice = this.$store.getters.getItemSpecialPrice(itemObj.PLU)
        if (specialPrice) {
          responses.showSuccess(`Special Detected`)
          price = specialPrice;
        }
        let tourismLevy = 0.0;
        if (set.tourism){
          let obj = { amount : price, incl : true }
          tourismLevy = await this.$store.dispatch('getTourismLevyAmount', obj);
        }
        let obj = {
          Plu: itemObj.PLU,
          Description: itemObj.Description,
          Qty: itemObj.Qty,
          tourismLevy,
          Unit: Number(price).toFixed(2),
          Line: Number(price * itemObj.Qty).toFixed(2),
          Discount: 0,
          vat: 0,
          instructions: []
        }
        this.$store.commit('addToCurrentSaleLines', obj)
        this.input = ''
      });
    },
    addToSale(item) {
      return this.$store.dispatch('getStockSetting', item.PLU)
          .then(async (set) => {
            let price = Number(item.SellingIncl.toFixed(2));
            let description = item.Description;

            if (set.askForDescription || set.askForBoth) {
              description = (await this.askForDescription(item));
            }

            if (set.askForPrice || set.askForBoth) {
              price = (await this.askForPrice(item));
            }

            if (set.cookingLink) {
              return this.$router.push({
                name: 'instruction',
                query: {plu: item.PLU, saleNumber: this.$route.query.saleNumber}
              });
            }
            let specialPrice = this.$store.getters.getItemSpecialPrice(item.PLU)
            if (specialPrice !== null && specialPrice !== undefined) {
              responses.showSuccess(`Special Detected`)
              price = specialPrice;
            }
            let tourismLevy = 0.0;
            if (set.tourism){
              let obj = { amount : price, incl : true }
              tourismLevy = await this.$store.dispatch('getTourismLevyAmount', obj);
            }
            let obj = {
              Plu: item.PLU,
              Description: description,
              Qty: 1,
              Unit: price,
              Line: price,
              tourismLevy,
              Discount: 0,
              vat: 0,
              instructions: []
            }
            this.$store.commit('addToCurrentSaleLines', obj)
            this.input = ''
            return this.$store.dispatch(`calculateTipAmount`)
          })
    },
    askForPrice(item) {
      this.$store.commit('setIsTabOpen', true)
      return Swal.fire({
        allowOutsideClick: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        title: `Set Selling Price`,
        html: `<h6 >Description : ${item.Description}</h6>
               <h6 >Current Price : ${$utils.formatMoney(item.SellingIncl)}</h6>
               <input type="number" step="0.01" id="selling-prc" class="swal2-input" placeholder="Selling Price" style="max-width: 70%">`,
        confirmButtonText: 'Set Price',
        focusConfirm: false,
        preConfirm: async () => {
          let value = Swal.getPopup().querySelector('#selling-prc').value;
          if (!value) {
            Swal.showValidationMessage(`Please enter a valid Selling Price`)
          }
          return parseFloat(value);
        }
      }).then(result => {
        this.$store.commit('setIsTabOpen', false)
        if (result.isConfirmed) {
          return result.value;
        }
        return parseFloat(item.SellingIncl.toFixed(2))
      })
    },
    askForDescription(item) {
      this.$store.commit('setIsTabOpen', true)
      return Swal.fire({
        allowOutsideClick: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        title: `Set Description`,
        html: `<h6 >Description : ${item.Description}</h6>
               <textarea id="desc-ask" class="swal2-input" placeholder="Description" style="max-width: 70%">`,
        confirmButtonText: 'Set Description',
        focusConfirm: false,
        preConfirm: async () => {
          let value = Swal.getPopup().querySelector('#desc-ask').value
          if (!value || value.toString().trim() === "") {
            Swal.showValidationMessage(`Please enter a valid Description`)
          }
          return value;
        }
      }).then(result => {
        this.$store.commit('setIsTabOpen', false)
        if (result.isConfirmed) {
          return result.value;
        }
        return item.Description
      })
    },
    getStock() {
      this.$utils.showLoading()
      const query = {
        description: this.input,
        category: '',
        subCategory: '',
        sub2Category: ''
      }
      return this.$store.dispatch('searchDescription', query)
          .finally(() => this.$utils.hideLoading())
    },
    hasLetters(inputString) {
      return /[a-zA-Z]/.test(inputString);
    },
    async onBarcodeScanned(barcode) {
      if (barcode.slice(-5) === `Enter`) {
        barcode = barcode.slice(0, -5);
      }
      if (!this.isTabOpen) {
        try {
          this.$store.commit(`setIsTabOpen`, true)
          let item;
          item = this.$store.getters.getByPlu(barcode)
          if (!item) {
            item = this.$store.getters.getByPlu(barcode.slice(1))
          }

          if (!item) {
            let itemBarcode = barcode.slice(0, -1);
            item = this.$store.getters.getByPlu(itemBarcode)
          }

          let settings = this.$store.getters.getDeviceSettings;
          let barcodeMakesPrice = this.systemSettings.priceMakesPrice;
          let flag = barcode.substring(0, 2);
          if (!item && barcode.length === 13 && settings && settings.Flag.toString() === flag) {
            let scaleItemPLU = barcode.toString().substring(2, 6);
            item = this.$store.getters.getByPlu(scaleItemPLU)
            if (item) {
              let barcodePrice = barcode.toString().substring(6, 12);
              if (settings.checkDigit) {
                barcodePrice = barcode.toString().slice(0, 6) + barcode.slice(7);
                barcodePrice = barcodePrice.toString().substring(6, 11)
              }
              let sellingIncl = item.SellingIncl;
              if (barcodeMakesPrice) {
                console.log()
                barcodePrice = barcodePrice.substring(0, barcodePrice.length - 2) + '.' + barcodePrice.substring(barcodePrice.length - 2, 6);
                barcodePrice = Number(barcodePrice);
                let qty = barcodePrice / sellingIncl;
                item.Qty = parseFloat(qty.toFixed(3));
              }else {
                barcodePrice = barcodePrice.substring(0, barcodePrice.length - 3) + '.' + barcodePrice.substring(barcodePrice.length - 3, 6);
                barcodePrice = Number(barcodePrice);
                let qty = barcodePrice;
                item.Qty = parseFloat(qty.toFixed(3));

              }
              return this.addScaleItem(item);
            }
          }

          if (item) {
            this.addToSale(item)
            this.input = ''
          } else {
            return this.getStock();
          }
        } finally {
          this.$store.commit(`setIsTabOpen`, false)
        }
      } else {
        this.getStock()
      }
    },
  },
  // mounted() {
  //   console.log('Component mounted, trying to focus:', this.$refs.inputField);
  //   this.$refs.inputField.focus();
  // },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputField.focus();
    });
  },
  created() {
    // this.$barcodeScanner.init(this.onBarcodeScanned)
  },
  destroyed() {
    // this.$barcodeScanner.destroy()
  }
}
</script>

<style lang="scss" scoped>
.row {
  background: #0092f7;
  padding: 0 10px;
}

button {
  background: #0092f7;
}

.stock-search-bar {
  background: #92A7BA;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  justify-content: flex-start;
  position: relative;

  .barcode-scanner {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 24px;
  }

  .viewOrder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 8px;
      width: 24px;
      height: 24px;
      background: #0092f7;
      border-radius: 50%;
    }
  }

  .search-icon {
    left: 67px;
    font-size: 18px;
    color: #40434A;
  }

  .stock-search-field {
    margin-left: 10px;
    height: 40px;
    width: 100%;
    border-radius: 10px;
    padding-left: 30px;
    outline: transparent;
  }
}
</style>
