import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse, faBarcode, faMagnifyingGlass, faPlus, faMinus, faBars, faAngleRight,faAngleLeft, faArrowLeft, faDeleteLeft,faPercent,
    faCartShopping, faPaperPlane, faClose, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,faAddressCard,
    faReceipt, faGear, faXmark, faChevronRight, faArrowRightFromBracket, faCreditCard, faMoneyBill, faChevronUp,faCrown,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faUserPlus, faHandHoldingDollar, faCoins, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave,faChartLine,faUpload,faTruck,faCancel,faDownload,
    faCommentsDollar,faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple,faHandHoldingDroplet,faEnvelope,faExchange,faHeartCircleCheck,
    faHeartCirclePlus,faRotateLeft,faPencil,faComments,faEllipsis,faFile,faImage,
    faArrowRight , faExclamationCircle,faBell ,faCodeMerge,faRightLeft , faBusinessTime,faMagnifyingGlassChart,faCircleDollarToSlot

} from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHouse, faBarcode, faMagnifyingGlass, faClose, faPlus,faFile, faImage,faMinus, faBars, faAngleRight, faArrowLeft,faCrown,
    faDeleteLeft, faCartShopping, faPaperPlane, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,
    faReceipt, faGear, faArrowRightFromBracket, faXmark, faChevronRight, faCreditCard, faMoneyBill, faChevronUp,faArrowRight,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus, faArrowRotateLeft,faHeartCirclePlus,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave, faArrowsRotate, faLayerGroup,faPercent,faHeartCircleCheck,
    faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus,faChartLine,faUpload,faTruck,faCommentsDollar,faCircleDollarToSlot,
    faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple, faHandHoldingDroplet,faEnvelope,faExchange,faCancel,faAddressCard,faRotateLeft,
    faExclamationCircle , faBell,faCodeMerge,faRightLeft,faAngleLeft , faBusinessTime,faMagnifyingGlassChart , faDownload,faPencil,faComments,faEllipsis
)

