import TimeManager from "../../services/TimeManager";
import {errorHandler} from "@/services/errorHandler";
import axios from "axios";
import {Storage} from "@/services/Storage";

export default {
    name: "tickets",
    state: {
        currentNotes: []
    },
    getters: {
        getCurrentNotes: (state) => state.currentNotes,
    },
    mutations: {
        setCurrentNotes: (state, data) => state.currentNotes = data,
        editTicketNote: (state, data) => {
            state.editNote = {
                id: data.id,
                Note: data.Note,
                internal: false,
                edit: true,
                readOnly: false
            }
        },
        replyToNote: (state, data) => {
            state.editNote = {
                Note: ``,
                internal: data.internal,
                replyTo: data.id,
                employee: data.createduser,
                edit: true,
                readOnly: false
            }
        },
    },
    actions: {
        replyToNote: (context, data) => {
            context.commit('replyToNote', data)
            context.commit('disableEditMode')
        },
        // getTicketDetails:(context , id)=> {
        //     return axios.get(ticketApi+`ticket/getDetails?id=`+id)
        //         .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        // },
        getTicketDetails:(context , id)=> {
            return axios.get(ticketApi+`ticket/getDetails?id=`+id)
                .then(async ({data})=> {
                    let ticket = await context.dispatch('getTicketByID', id)
                    let ticketNotes = ticket.notes;
                    context.commit('setCurrentNotes', ticketNotes)
                    return data;
                }).catch(err=>errorHandler.tomcatError(err))
        },
        getTicketByID: (context, id) => {
            return axios.get(ticketApi + `ticket/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        // getTicketById:(context , id)=> {
        //     return axios.get(ticketApi+`ticket/getByID?id=`+id)
        //         // .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        // },
        editTicketNote: (context, data) => {
            context.commit('editTicketNote', data)
            context.commit('enableEditMode')
        },
        addDocumentToTicket: async (context, obj) => {
            let user = await Storage.getCurrentUser();
            obj.attached_by = user.Username+` (Staff)`
            return axios.post(ticketApi + `ticket/${obj.ticketID}/attachment/uploadFile`, obj)
                .catch((err) => errorHandler.tomcatError(err))
        },
        openAttachment: (context, obj) => {
            return window.open(ticketApi+`ticket/${obj.ticket}/attachment/${obj.attachmentID}`)
        },
        getTicketById:(context , id)=> {
            return axios.get(ticketApi+`ticket/getByID?id=`+id)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        addNoteToTicket: async (context, payload) => {
            return context.dispatch('createNote', payload)
                .then(async () => {
                    let ticket = await context.dispatch('getTicketByID', payload.ticketid)
                    let ticketNotes = ticket.notes;
                    context.commit('setCurrentNotes', ticketNotes)
                }).catch((err) => errorHandler.tomcatError(err))
        },
        createNote: async (context, payload) => {
            payload.internal = false;
            return axios.post(ticketApi + `ticket/notes`, payload)
                .catch((err) => errorHandler.tomcatError(err))
        },
        deleteDocumentFromTicket: async (context, obj) => {
            return axios.delete(ticketApi + `ticket/${obj.ticket}/attachment/removeFile/${obj.attachmentID}`)
                .catch((err) => errorHandler.tomcatError(err))
        },
        updateTicketNote: async (context, payload) => {
            return axios.put(ticketApi + `ticket/notes/${payload.id}`, payload)
                .then(async () => {
                    let ticket = await context.dispatch('getTicketByID', payload.ticketid)
                    let ticketNotes = ticket.notes;
                    context.commit('setCurrentNotes', ticketNotes)
                }).catch((err) => errorHandler.tomcatError(err))
        },
        getAttachmentsByTicket: (context, id) => {
            return axios.get(ticketApi + `ticket/${id}/attachment/getAttachments`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        createNoteView: (context, ticketID) => {
            return Swal.fire({
                allowOutsideClick: false,
                titleText: `Add note`,
                inputAutoTrim: true,
                showCancelButton: true,
                showLoaderOnConfirm: true,
                html: `
                        <div class="d-flex flex-column align-items-start gap-1 p-1">
                            <h4 >Ticket#${ticketID}</h4>
                            <div class="form-check form-switch">
                                <input class="form-check-input" checked type="checkbox" role="switch" id="isInternal">
                                <label class="form-check-label" for="isInternal">internal</label>
                            </div>
                            <textarea class="w-100 form-control" id="note" placeholder="Add Comment..." rows="6" >N/A</textarea>
                        </div>
                        `,
                focusConfirm: false,
                confirmButtonText: 'Save',
                preConfirm: async () => {
                    let note = Swal.getPopup().querySelector('#note').value
                    // let isInternal = Swal.getPopup().querySelector('#isInternal').checked;
                    if (!note) {
                        return Swal.showValidationMessage(`Please add a validate comment`);
                    }
                    let user = await Storage.getCurrentUser();
                    return context.dispatch('addNoteToTicket', {
                        Note: note,
                        createduser: user,
                        internal: false,
                        ticketid: Number(ticketID)
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire({
                        title: `Success`,
                        icon: 'success',
                        text: `Done`
                    })
                }
            })
        },
    }
}
