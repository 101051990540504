import Vue from 'vue'
import Vuex from 'vuex'
import salesModule from "@/store/modules/Sale/sales-module";
import emailModule from "@/store/modules/Sale/email-module"
import ticketModule from './modules/ticket-module'
import stockModule from "@/store/modules/Stock/stockmodule";
import stockCategories from "@/store/modules/Stock/stockcategory-module";
import floatModule from "@/store/modules/Sale/float-module";
import tableModule from "@/store/modules/Sale/table-module";
import cookingInstruction from "@/store/modules/Sale/cookingInstruction";
import stockTake from '@/store/modules/Stock/stock-take-module';
import paymentMethods from '@/store/modules/Sale/payment-methods-module.js';
import accounts from "@/store/modules/Accounts/accounts";
import kazangModule from "@/store/modules/Sale/kazang_module";
import supplierModule from "@/store/modules/supplier-module.js";
import stockReceivedModule from "@/store/modules/Stock/stock-received-module";
import stockTransferModule from "@/store/modules/Stock/stock-transfer-module";
import kazangPayment from "@/store/modules/Kazang/payment";
import kazangSignUp from "@/store/modules/Kazang/sign-up-module";
import customInstall from "@/store/modules/Sale/custom-install-module";
import billingModule from "@/store/modules/billing-module";
import backOfficeModule from "@/store/modules/backoffice-module";
import timeModule from "@/store/modules/time-module";
import user_security_module from "@/store/modules/user/user_security_module";
import vatCalculatorModule from "@/store/modules/vat_calculator";
import axios from "axios";
import {Storage} from "@/services/Storage"

// test for indexed db
import {db} from '@/services/db'


import {responses} from "@/utils/responses";
import userModule from "@/store/modules/user_module";
import taxModule from "@/store/modules/tax-module";
import layByModule from "@/store/modules/Sale/lay-by-modules";
import loyaltyModule from "@/store/modules/Sale/loyalty-module";
import router from "@/router";
import queryStringify from "qs-stringify";
import Swal from "sweetalert2";
import ticket_notes_module from "@/store/modules/ticket_notes_module";

Vue.use(Vuex)
let CurrentTime = function () {
    let date = new Date();
    let d = date.toISOString().split('T')[0]
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let t = hour + ':' + minute + 'hrs';
    return d + ` at ` + t;
}
const store = new Vuex.Store({
    state: {
        ResetCount: 0,
        registeredStores: [],
        defaultStore: {},
        device_settings: {},
        systemSettings: {},
        lastUpdated: '',
        registrationDetails: {},
        zedMobileStatus: null,
        refundReasons: [
            {
                code: "01",
                description: "Missing Quantity"
            },
            {
                code: "02",
                description: "Missing Item"
            },
            {
                code: "03",
                description: "Damaged"
            },
            {
                code: "04",
                description: "Wasted"
            },
            {
                code: "05",
                description: "Raw Material Shortage"
            },
            {
                code: "06",
                description: "Refund"
            },
            {
                code: "07",
                description: "Wrong Customer TPIN"
            }
        ],
        loading: false,
        isUpdateAvailable: false,
        isAndroid: true,
        tot: false,
        overrideDetails: ``,
        zedMobileCategories: [],
        isSmartInvoicing: false,
    },
    getters: {
        getResetCount:(state) => state.ResetCount,
        getSmartInvoicingStatus: (state) => state.isSmartInvoicing,
        getRegisteredStores: (state) => state.registeredStores,
        getRefundReasons: (state) => state.refundReasons,
        getOverrideDetails: (state) => state.overrideDetails,
        getRegistrationDetails: (state) => state.registrationDetails,
        getLoadingState: (state) => state.loading,
        isTot: (state) => state.tot,
        getUpdateAvailability: (state) => state.isUpdateAvailable,
        getAndroid: (state) => state.isAndroid,
        getDefaultStore: (state) => state.defaultStore,
        getDeviceSettings: (state) => state.device_settings,
        getLasUpdated: (state) => state.lastUpdated,
        getSystemSettings: (state) => state.systemSettings,
        getZedMobileCategories: (state) => state.zedMobileCategories,
        getZedMobileStatus: (state) => !!state.zedMobileStatus,
        getZedMobileApiKey: (state) => state.zedMobileStatus,
        currentDate: () => {
            let date = new Date();
            return date.toISOString().split('T')[0];
        },

        currentTime: () => {
            let date = new Date();
            let d = date.toISOString().split('T')[0]
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let t = hour + ':' + minute;
            return d + `T` + t;
        },
    },
    mutations: {
        setResetCount: (state, count) => {state.ResetCount = count},
        setSmartInvoicingStatus: (state, payload) => state.isSmartInvoicing = payload,
        toggleNavigation: (state, width) => state.navigation = width,
        setLoadingState: (state, payload) => state.loading = payload,
        setTot: (state, payload) => state.tot = payload,
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
        setOverrideDetails: (state, payload) => state.overrideDetails = payload,
        setRegisteredStores: async (state, data) => {
            if (!data) data = await Storage.getAllStores();
            else await Storage.setAllStores(data);
            state.registeredStores = data
        },
        setLastUpdated: (state, payload) => state.lastUpdated = payload,
        setDeviceSettings: async (state, payload) => {
            if (payload) {
                await Storage.setDeviceSettings(payload);
            } else {
                payload = await Storage.getDeviceSettings()
            }
            state.device_settings = payload
        },
        getRefundReasons: (state) => state.refundReasons,
        setSystemSettings: async (state, payload) => {
            if (payload) await Storage.setSystemSettings(payload);
            else payload = Storage.getSystemSettings();
            state.systemSettings = payload
        },

        setRegistrationDetails: async (state, payload) => {
            if (payload) await Storage.setRegistrationDetails(payload);
            else payload = Storage.getRegistrationDetails()
            state.registrationDetails = payload
        },
        setZedMobileStatus: async (state, payload) => {
            if (payload) await Storage.setZedMobileStatus(payload);
            else payload = Storage.getZedMobileStatus()
            state.zedMobileStatus = payload
        },
        setZedMobileCategories: async (state, payload) => state.zedMobileCategories = payload,

        setDefaultStore: (state, data) => {
            state.defaultStore = data
            return Storage.setDefaultStore(data)
        },
    },
    actions: {
        refreshState: async (context) => {
            let validate = await context.dispatch(`validatePCRegistration`);
            if (!validate.success) {
                return responses.throwErr(validate.message)
                    .then(() => {
                        return context.dispatch(`clearStoreState`)
                            .then(async () => {
                                let deviceID = await Storage.getDeviceID();
                                localStorage.clear();
                                await Storage.setDeviceID(deviceID)
                                try {
                                    await this.$router.push({name: 'login'})
                                } catch (e) {
                                }
                                return window.location.reload(true);
                            });
                    });
            }
            let promiseArray = [
                context.commit(`setAllStores`),
                context.commit(`setCurrentStore`),
                context.commit(`setCurrentUser`),
            ];
            let storeDB = await Storage.getCurrentStoreDB();
            if (storeDB) {
                promiseArray.push(
                    context.dispatch(`getExpiryDateByCustomerID`),
                    context.dispatch(`updateToLatestVersion`),
                    context.dispatch(`getAllTaxLabels`),
                    context.dispatch(`getAllUsers`),
                    context.dispatch(`getAllSecurities`),
                    context.dispatch(`getCategories`),
                    context.dispatch(`getBaseCurrency`),
                    context.dispatch(`getAllCurrencies`),
                    context.dispatch(`checkSmartInvoicing`),
                    context.dispatch(`getKazangDetails`),
                    context.dispatch(`getAllPaymentMethods`),
                )
            }
            return Promise.all(promiseArray).catch(err => console.error(err));
        },
        checkSmartInvoicing: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            let user = await Storage.getCurrentUser();
            return axios.get(franchiseTomcatApi + `GRV/${storeDB}/checkSmartInvoicing`)
                .then(({data}) => {
                    context.commit(`setSmartInvoicingStatus`, data)
                    let isLive = context.getters.getSmartInvoicingLive;
                    if (!data && isLive) {
                        return context.dispatch(`checkIfStoreDocsSubmitted`).then(() => {
                            let hasDocs = context.getters.getDocSubmissionStatus;

                            // if (!hasDocs && !!user) {
                            //     return Swal.fire({
                            //         title: 'Register for Smart Invoicing Now',
                            //         text: 'You are not registered for smart invoicing. Please register now.',
                            //         icon: 'warning',
                            //         showCancelButton: true,
                            //         showCloseButton : true,
                            //         allowOutsideClick : false,
                            //         confirmButtonText: 'Register',
                            //         cancelButtonText: 'Cancel'
                            //     }).then((result) => {
                            //         if (result.isConfirmed) {
                            //             return router.push({name: 'smart-invoicing-setup'});
                            //         }
                            //     });
                            // }
                        })
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },
        syncData: async ({dispatch, commit, getters}) => {
            let validate = await dispatch(`validatePCRegistration`);
            if (!validate.success) {
                await responses.deviceErr(validate.message, `Registration failed`)
                return router.push({name: `setup`})
            }
            await dispatch('getDeviceSettings')
            return axios.all([
                dispatch("getExpiryDateByCustomerID"),
                dispatch("updateToLatestVersion"),
                dispatch("getCustomerDetails"),
                dispatch('getAllStock'),
                dispatch('getAllUsers'),
                dispatch('getBaseCurrency'),
                dispatch('getAllCurrencies'),
                dispatch("getCategories"),
                dispatch("isKazangIntegrated"),
                dispatch("getAllPaymentMethods"),
                dispatch("getAllActiveSpecials"),
                dispatch("getAllTaxLabels"),
                dispatch("getAllStores"),
                dispatch("getAllFranchiseSettings"),
                dispatch("getAllSecurities"),
                dispatch("getSystemSettings"),
                dispatch("isLoyaltyActivated"),
                dispatch("isZedMobileActivated"),
            ]).then(() => {
                commit("setCurrentUser");
                commit("setSaleCurrency");
                commit("setKazangSession");
                commit('setLastUpdated', CurrentTime())
            });

        },
        appLogin: (context, payload) => {
            return axios.post(backOfficeTomcatApi + 'mobileUser/login', payload)
                .then(async ({data}) => {
                    await Storage.setAdminUser(payload)
                    await context.commit('setRegisteredStores', data)
                }).catch((err) => errorHandler.tomcatError(err))
        },

        isZedMobileActivated: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(zedMobileApi + 'auth/lytsoft/checkZedMobileStatus', {
                headers: {
                    DB: window.btoa(store.StoreDB)
                }
            }).then(({data}) => {
                context.commit(`setZedMobileStatus`, data)
                if (!!data) {
                    return axios.get(zedMobileApi + 'products/getCategories', {
                        headers: {"Api-key": data}
                    }).then(response => context.commit(`setZedMobileCategories`, response.data))
                }
            }).catch((err) => errorHandler.tomcatError(err))
        },

        getCustomerDetails: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `stores/getRegistrationDetails`, {
                params: {database: store.StoreDB}
            }).then(({data}) => context.commit(`setRegistrationDetails`, data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        confirmationText: (context, payload) => {
            return Swal.fire({
                title: 'Authorization',
                icon: 'info',
                html:
                    '<div style="font-family: Arial, sans-serif;' +
                    '        font-size: 16px;' +
                    '        line-height: 1.5;' +
                    '        color: #333;' +
                    '        background-color: #f7f7f7;' +
                    '        padding: 20px;' +
                    '        border: 1px solid #ccc;' +
                    '        border-radius: 5px;' +
                    '        margin: 20px 0;">' +
                    '<p><strong>Please note:</strong> By clicking on the "Confirm" button, you are authorizing the ' +
                    'automatic monthly deduction of K50.00 from your Kazang account as part of the subscription model ' +
                    'for this software.</p></div>',
                showCloseButton: false,
                showCancelButton: true,
                confirmButtonText: `Confirm`,
                focusConfirm: false,
            }).then((res) => {
                if (res.isConfirmed) {
                    return context.dispatch(`setupStore`, payload)
                }
            })
        },

        setupStore: async (context, payload) => {
            return Swal.fire({
                title: 'Please Enter your TPIN',
                icon: 'question',
                input: 'number',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                preConfirm: async (inputValue) => {
                    payload.DeviceID = await Device.getDeviceId();
                    payload.data = await Storage.getAdminUser();
                    payload.tpin = inputValue;
                    return axios.post(franchiseTomcatApi + `AndroidLogin/SecondeReg`, payload)
                        .then(({data}) => context.dispatch(`loginToStore`, data))
                        .catch((err) => errorHandler.tomcatError(err))
                },
            });
        },

        loginToStore: async (context, payload) => {
            await context.commit(`setDefaultStore`, payload);
            await context.dispatch(`syncData`);
            let userCount = await context.getters.countNonLytSoftUsers;
            if (userCount === 0 || !userCount) {
                return router.push({name: 'create-initial-user'})
            }
            return router.push({name: 'poslogin'})
        },
        getDeviceSettings: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            let android = context.getters.getAndroid;
            let errorTitle = android ? `Error on Settings` : `Hardware Service Error, Please restart your computer, but if problem persist,`;
            return Device.getPCName(android).then(device => {
                return axios.get(serverApi + `settings/${store.StoreDB}/${device}`)
                    .then(({data}) => context.commit('setDeviceSettings', data))
                    .catch((err) => errorHandler.tomcatError(err, false, 'Error on Settings'))
            }).catch(err => errorHandler.tomcatError(err, false, errorTitle))
        },

        getFloatUsers: async () => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `cashup/${store.StoreDB}/noneFloat`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        assignFloat: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            payload.device = settings.PC
            return axios.post(`${serverApi}float/assign/${store.StoreDB}`, payload)
                .then(({data}) => {
                    if (!data.assigned) {
                        responses.throwErr(data.message)
                        return false;
                    }
                    context.commit('setUserFloat', {
                        user: payload.user,
                        device: payload.device,
                        supervisor: data.supervisor,
                        float: Number(payload.amount)
                    });
                    return true;
                }).catch((err) => errorHandler.tomcatError(err))
        },
        checkFloat: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}float/check/${store.StoreDB}`, payload)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getcashUpDiff: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}cashup/${store.StoreDB}/status`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        saveSettings: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}settings/${store.StoreDB}`, payload).then(({data}) => {
                context.commit('setDeviceSettings', data)
                return context.dispatch(`syncData`);
            }).catch((err) => errorHandler.tomcatError(err))
        },
        getAllPrinters: () => {
            return axios.get(hardwareApi + `utils/getPrinters`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err, false, 'Hardware Service Error, Please restart your computer, but if problem persist,'))
        },
        getStockLocations: async () => {
            let store = await Storage.getDefaultStore();
            return axios.get(`${serverApi}locations/${store.StoreDB}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        cashUpUser: async (context, payload) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`, `CompleteCashup`);
            if (!isAllowed) throw new Error(`User not allowed to Cashup`);
            let store = await Storage.getDefaultStore()
            let device = await Storage.getDeviceSettings();
            payload.PC = device.PC

            let float = await Storage.getFloat()
            float = float ? float.filter(f => f.user !== payload.user) : [];
            $utils.showLoading();
            return axios.post(`${serverApi}cashup/${store.StoreDB}`, payload)
                .then(({data}) => {
                    let cashupNumber = data.response.cashupNum;
                    return axios.all([
                        Storage.setFloat(float),
                        context.dispatch(`printCashup`, cashupNumber)
                    ]).finally(() => responses.showInfo('Cash up completed successfully'));
                }).catch((err) => errorHandler.tomcatError(err))
        },

        printCashup: async (context, cashupNumber) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`, `PrintCashUp`);
            if (!isAllowed) throw new Error(`User not allowed to print cashup`);
            let user = await Storage.getCurrentUser();
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            if (settings.CashupPrintStyle !== `SLIP` || settings.CashupPrintStyle == `0`) {
                const apiUrl = `https://chrilantech.com/LytSoftPosOnline/api/PDFReport/CashUp/${cashupNumber}/${store.StoreDB}`;
                return window.open(apiUrl, '_blank');

            } else if (context.getters.getAndroid) {
                return axios.get(serverApi + `cashup/${store.StoreDB}/${cashupNumber}?useBT=true`)
                    .then(({data}) => window.location.href = `rawbt:base64,${data}`)
                    .catch((err) => errorHandler.tomcatError(err, false, `Cashup Print Error`))

            } else {
                return axios.post(hardwareApi + `printing/printCashup/${store.StoreDB}/${cashupNumber}/${user.Username}`, settings)
                    .catch((err) => errorHandler.tomcatError(err, false, `Cashup Print Error`))
            }
        },

        getBaseCurrency: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `currency/${store.StoreDB}/getBaseCurrency`)
                .then(({data}) => context.commit('setBaseCurrency', data))
                .catch((err) => errorHandler.tomcatError(err))
        },
        upgradeKazangPOSCustomer: async (context, payload) => {
            let obj = queryStringify(payload);
            return axios.put(franchiseTomcatApi + `stores/upgradeKazangPOSCustomer?` + obj)
                .then(() => context.dispatch(`getCustomerDetails`))
                .catch((err) => errorHandler.tomcatError(err))
        },

        checkExpiry: async () => {
            let store = await Storage.getDefaultStore();
            if (!store) {
                return;
            }
            let storeDB = store.StoreDB;
            return axios.get(backOfficeApiGET, {
                params: {
                    route: `other/getExpiryDate?Database=` + storeDB,
                }
            }).then(({data}) => {
                let expireDate = new Date(data);
                let today = new Date();
                return expireDate < today;
            });
        },
        updateToLatestVersion: async () => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.put(franchiseTomcatApi + `version/${store.StoreDB}/updateToLatest`);
        },
        setDefaultStore: (context, data) => {
            context.commit('setDefaultStore', data)
            return context.dispatch('syncData')
        },

        validatePCRegistration: async (context) => {
            let deviceID = await Device.getDeviceId();
            let store = await Storage.getDefaultStore();
            if (!store) return {success: true, message: `Success`};
            let obj = {
                customerID: store.CustomerID,
                deviceID: deviceID,
                platform : context.getters.getAndroid?'Android RetailPOS':'RetailPOS'
            }
            return axios.get(subscriptionApi + `devices/validatePCRegistration`, {params: obj})
                .then(() => {
                    return {success: true, message: `Success`};
                }).catch(err => {
                    let errorMessage = errorHandler.franchiseLoginError(err)
                    return {success: false, message: errorMessage};
                });
        },
        getApikey: () => {
            // Example function to fetch the API key from an API endpoint
            fetch('https://example.com/api/getApiKey')
                .then(response => response.json())
                .then(data => {
                    // Assuming the API key is in data.apiKey
                    const apiKey = data.apiKey;
                    // Store the API key in local storage
                    localStorage.setItem('apiKey', apiKey);
                    console.log('API key stored in local storage:', apiKey);
                })
                .catch(error => {
                    console.error('Error fetching API key:', error);
                });
        },

        getSystemSettings: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `systemsetting/${store.StoreDB}`)
                .then(({data}) => context.commit(`setSystemSettings`, data))
        },
        fetchResetCount: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            try {
                const response = await axios.get(`${subscriptionApi}devices/getResetStatusByStore?DB=${storeDB}`);
                context.commit('setResetCount', response.data);
                return response.data;
            } catch (err) {
                return  errorHandler.tomcatError(err);
            }
        },
        ResetDevices: async (context, user) => {
            const store = await Storage.getDefaultStore();
            const payload = {
                user: user,
                DB: store.StoreDB,
            }
            try {
                const response = await axios.post(`${subscriptionApi}devices/resetDevices`, payload);
                return response.data;
            } catch (err) {
                return errorHandler.tomcatError(err);
            }
        },

    },
    modules: {
        salesModule, stockModule, stockCategories, userModule, floatModule, tableModule, emailModule, kazangPayment,
        cookingInstruction, stockTake, paymentMethods, accounts, kazangModule, supplierModule, stockReceivedModule,
        stockTransferModule, kazangSignUp,ticketModule, customInstall, billingModule, backOfficeModule, timeModule,taxModule,
        user_security_module,loyaltyModule, layByModule,vatCalculatorModule,ticket_notes_module,db
    }
})
export default store;