import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/icons.js'
import DrawerLayout from 'vue-drawer-layout'
import './services/GlobalVariables'
import './services/ProxyHandler'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueToggles from 'vue-toggles';
import Loading from 'vue-loading-overlay';
import vSelect from 'vue-select'
import {VueEditor} from "vue2-editor";
import "vue-select/dist/vue-select.css";
// Import stylesheet

import 'vue-loading-overlay/dist/vue-loading.css';
import VueBarcodeScanner from 'vue-barcode-scanner'
let options = {
    sensitivity: 300, // default is 100
    requiredAttr: true, // default is false
    controlSequenceKeys: ['NumLock', 'Clear'], // default is null
    callbackAfterTimeout: true // default is false
}
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import VueCompositionAPI from "@vue/composition-api";

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

Vue.use(VueCompositionAPI);
Vue.use(VueGoodTablePlugin);
Vue.use(VueBarcodeScanner, options);

Vue.component('loading', Loading);
Vue.component('VueToggles', VueToggles);
Vue.use(VueVirtualScroller)
Vue.use(DrawerLayout);
Vue.component('vue-editor',VueEditor)
Vue.component('v-select',vSelect)
Vue.config.productionTip = false
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue);

new Vue({
    router,
    store,
    render: h => h(App),
    async beforeMount() {
        let device = await Device.getDeviceId(); // Ensure `Device` is defined and accessible
        this.$OneSignal.init({appId: 'bd1f95f2-de76-47c1-ad1c-6d79d10990f2'});

        const pushSubscriptionChangeListener = async (event) => {
            try {
                let device = await Device.getDeviceId();
                // console.log('Robert is working', device);

                if (event.current.token) {
                    this.$OneSignal.login(device);
                }
            } catch (error) {
                console.error('Error in pushSubscriptionChangeListener:', error);
            }
        };
        this.$OneSignal.User.PushSubscription.addEventListener(
            "change",
            pushSubscriptionChangeListener
        );
    }
}).$mount('#app')
