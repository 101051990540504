import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";
import currencyModule from "@/store/modules/currency-module";

export default {
    name: 'accounts',
    state: {
        debtors : [],
        customer: '',
    },
    getters: {
        getDebtors: (state) =>state.debtors,
        getDebtorByID : (state) => (id) => {
            let customer = state.debtors.find(debtor => debtor.Id === id)
            if (customer){
                return customer.Name;
            }
            return id;
        },
        getCustomer: (state) => state.customer,
    },
    mutations: {
        setDebtors : (state , data) => state.debtors = data,
        setCustomer: (state, data) => {
            state.customer = data;
            let debtor = state.debtors.find(deb => deb.Id === data);
            if (debtor){
                let currency = state.currencyModule.storeCurrencies.find(c=>c.Symbol === debtor.Symbol);
                if (currency){
                    state.currencyModule.saleCurrency = currency;
                }
            }
        },
    },
    actions: {
        getAllDebtors : async (context,payload) => {
            let store = await Storage.getDefaultStore();
            payload = {
                ...payload,
                blocked: false
            }
            return axios.post(franchiseTomcatApi + `debtor/${store.StoreDB}/search`,payload)
                .then(({data})=> context.commit('setDebtors', data))
                .catch((err) => errorHandler.tomcatError(err))
        },
        createDebtor : async (context , payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(serverApi + `debtor/${store.StoreDB}`,payload)
                .then(({data})=> context.dispatch(`getAllDebtors`,{
                    Name: "",
                    tpin: "",
                    contact: ""
                }).then(()=>data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        printDebtorStatement : async (context,id) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `getStatement/${store.StoreDB}?ID=${id}`,)
                .then(({data})=> {
                    try {
                        Printing.printStatement(JSON.stringify(data))
                    }catch (e) {}
                }).catch((err) => errorHandler.tomcatError(err))
        },
        makeDebtorPayment: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            payload.User = user.Username;
            let obj = {
                route : `debtor/${store.StoreDB}/makeCashierPayment`,
                jsonBody : JSON.stringify(payload)
            }
            return axios.post(backOfficeApiPOST , obj)
                .then(() => {
                    try {
                    }catch (e) {}
                    context.commit(`setCustomer`,'')
                    responses.showSuccess(`Payment Successfully Made`)
                    return router.push({ name : 'pos' })
                }).catch((err) => errorHandler.tomcatError(err,true))
        },

        firstKazangAccountPaymentStep : async (context,payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(kazangApi + `pay/${store.StoreDB}`, {
                session: context.getters.getKazangSessionID,
                amount: Number(payload.PayedAmount.toFixed(2)),
                cell: payload.customerContact
            }).then(({data}) => {
                if (!data.secondStep && data.Response === '0') {
                    return context.dispatch('makeDebtorPayment', payload)
                } else if (data.Response === '3') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response === '41') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response !== '0') {
                    throw new Error(data.Print)
                } else {
                    context.commit('setKazangSession', data.newSession)
                    return data;
                }
            }).catch((err) => {
                context.commit('clearKazangSession')
                errorHandler.tomcatError(err, true)
            })
        },

        secondKazangAccountPaymentStep : async (context,firstStepResponse) => {
            let store = await Storage.getDefaultStore();
            let network = await context.dispatch('checkCustomerNetwork', firstStepResponse.customerContact);
            let route = ''

            switch (network) {
                case 'MTN':
                    route = '/mtnApprove';
                    break
                case 'Airtel':
                    route = '/airtelApprove';
                    break
                default:
                    route = '/zamtelApprove'
                    break
            }
            let url = kazangApi + `pay/${store.StoreDB}${route}`;
            return axios.post(url, JSON.parse(firstStepResponse.SecondStepPayload))
                .then(({data}) => {
                    if (!data.Error) {
                        if (!data.approved) {
                            return {
                                Error: 'Not Confirmed'
                            }
                        }
                        return context.dispatch('makeDebtorPayment', firstStepResponse.paymentData)
                    }
                    return data
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err)
                })
        },
        getCustomerById:async (context,id)=>{
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi+`debtor/${store.StoreDB}/${id}`)
                .then(({data})=>context.state.debtors.push(data))
        },
    },
    modules : { currencyModule }
}
