<template>
  <div class="sales-panel container-fluid h-100 w-100">
    <!-- Header Section -->
    <div v-if="showHeader" class="container d-flex float-row align-items-center sale-header">
      <div class="row header">
        <button v-if="showBack"
                @click="close"
                class="rounded-circle bg-transparent m-1">
          <font-awesome-icon icon="fa-solid fa-arrow-left"></font-awesome-icon>
        </button>
      </div>
      <a v-if="$store.getters.getZedMobileStatus" class="btn fw-bold text-primary mx-2" data-bs-toggle="modal"
         data-bs-target="#exampleModal">Create Voucher</a>
      <a class="btn fw-bold text-primary" @click="selectCustomer" v-if="$route.name!==`viewSale`">Add Customer</a>

      <!-- New Buttons -->
      <button id="openCustomerInformationModalButton" class="btn btn-primary d-none" data-bs-toggle="modal"
              data-bs-target="#amountInputModal">Open Amount Input Modal
      </button>
      <button id="openAmountInputModalButton" class="btn btn-primary d-none" data-bs-toggle="modal"
              data-bs-target="#customerInfoModal">Open Customer Information Modal
      </button>

      <!-- Modals -->
      <!-- Modal for Amount Input -->
      <div class="modal fade" id="amountInputModal" tabindex="-1" aria-labelledby="amountInputModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="amountInputModalLabel">Enter Amount</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="customerPhoneNumber" class="form-label">cell Number</label>
                <input type="tel" class="form-control" id="customerPhoneNumber" v-model="cell" min="10" maxlength="10"
                       placeholder="Enter phone number" required>
              </div>
              <div class="mb-3">
                <label for="cell" class="form-label">Amount</label>
                <input type="number" class="form-control" id="cell" v-model="amount" placeholder="Enter amount"
                       required>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" id="amountCloseBtn" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="handleAmountInputSave">Save</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for Customer Information -->
      <div class="modal fade" id="customerInfoModal" tabindex="-1" @hidden.bs.modal="resetCustomerInfoFields"
           aria-labelledby="customerInfoModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerInfoModalLabel">Enter Customer Information</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="phoneNumber" class="form-label">cell Number</label>
                <input type="tel" class="form-control" id="phoneNumber" maxlength="10" v-model="cell"
                       placeholder="Enter phone number" required>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" id="closemodal" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="handleCustomerInfoSave">Save</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for Voucher Purchasing Screen -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Voucher Purchasing Screen</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="max-height: 75vh; overflow-y: auto;">
              <div class="row">
                <!-- Left side: existing design -->
                <div class="col-md-4">
                  <div class="list-group">
                    <a class="list-group-item list-group-item-action" v-for="(option, index) in options" :key="index"
                       @click="selectOption(option)">
                      {{ option }}
                    </a>
                  </div>
                </div>
                <!-- Right side: list of other clickable options -->
                <div class="col-md-8">
                  <div class="product-card" v-for="item in selectedItem" :key="item.PLU" @click="checkItemOffer(item)">
                    <div class="stock-card_img mx-2 mt-2" v-if="item.Image">
                      <img :src="item.Image" alt="Item Image">
                    </div>
                    <div class="card-body mx-3 p-1 d-flex justify-content-between flex-column">
                      <span class="text-secondary text-sm">Stock Code : {{ item.PLU }}</span>
                      <h6 class="product-description">
                        {{ item.Description }} <br>
                        <span class="product-description2">{{ item.Description2 }}</span>
                      </h6>
                      <h5 class="product-price">{{ $utils.formatMoney(item.SellingIncl) }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" id="closebutton" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <span class="badge bg-info m-2" v-if="tipAmount > 0">Tip : {{ $utils.formatMoney(tipAmount) }}</span>
    </div>

    <!-- Customer Information -->
    <b class="m-2" v-if="customer">Customer : {{ customer }}</b>

    <!-- Sale Lines Table -->
    <div ref="saleLinesTable" class="sale-lines-table">
      <ul class="sales-list">
        <li :key="index" v-for="(sale, index) in currentSaleLines">
          <CurrentSaleLine :sale-line="sale"/>
        </li>
      </ul>
    </div>

    <!-- Sale Stats -->
    <div class="sale-stats">
      <SaleDetails/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import CurrentSaleLine from "@/components/point-of-sale/sales/CurrentSaleLine";
import SaleDetails from "@/components/point-of-sale/sales/SaleDetails";

export default {
  watch: {
    currentSaleLines() {
      this.scrollToBottom();
    }},
  name: "CurrentSaleTable",
  components: {SaleDetails, CurrentSaleLine},
  props: {
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showBack: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedCategory: null,
      selectedItem: [],
      amount: '', // Bind to Amount input
      cell: '', // Bind to Phone Number input
      amountPaid: '', // Bind to Amount Paid input
      currentItem: null // To track the currently selected item
    };
  },
  computed: {

    tipAmount() {
      return this.$store.getters.getTipAmount;
    },
    options() {
      return this.$store.getters.getZedMobileCategories;
    },
    customer() {
      const id = this.$store.getters.getCustomer;
      return this.$store.getters.getDebtorByID(id);
    },
    currentSaleLines() {
      return this.$store.getters.getSaleLines;
    }
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.saleLinesTable;
        container.scrollTop = container.scrollHeight;
      });
    },
    CreateVoucher() {
      this.$router.push({name: 'CreateVoucher'});
    },
    selectCustomer() {
      this.$router.push({name: 'select-customer', query: {saleNumber: this.$route.query.saleNumber}});
    },
    close() {
      this.$router.push({name: 'pos'});
    },

    checkItemOffer(item) {
      this.currentItem = item; // Track the selected item
      const hasOffer = item.offer;

      if (hasOffer) {
        this.triggerModal('openAmountInputModalButton');
      } else {
        this.triggerModal('openCustomerInformationModalButton');
      }
    },
    triggerModal(buttonId) {
      const button = document.getElementById(buttonId);
      if (button) {
        button.click();
      }
    },

    selectOption(option) {
      this.$utils.showLoading();
      this.selectedCategory = option;
      return axios.get(zedMobileApi + 'products/getByCatgeory', {
        params: {category: option},
        headers: {"Api-key": this.$store.getters.getZedMobileApiKey}
      }).then(response => this.selectedItem = response.data)
          .catch(error => errorHandler.tomcatError(error))
          .finally(() => this.$utils.hideLoading());
    },
    resetAmountInputFields() {
      this.amount = '';
      this.cell = '';
      document.getElementById("amountCloseBtn").click();
    },

    resetCustomerInfoFields() {
      this.cell = '';
      document.getElementById("closemodal").click();
    },
    handleAmountInputSave() {
      if (this.currentItem) {
        this.addToSaleWithAmount({
          ...this.currentItem,
          amount: this.amount,
          cell: this.cell,

        });
        this.resetAmountInputFields()
      }
    },

    handleCustomerInfoSave() {
      if (this.currentItem) {
        this.addToSaleWithPhoneNumber({
          ...this.currentItem,
          cell: this.cell,
        });
        this.resetCustomerInfoFields()
      }
    },

    addToSaleWithAmount(item) {
      let obj = {
        Plu: item.PLU,
        Description: item.Description,
        Qty: 1,
        Unit: item.amount,
        Line: item.amount,
        Discount: 0,
        vat: 0,
        tourismLevy : 0.0,
        instructions: [],
        voucher: true,
        cell: item.cell || "",
        offer: item.offer || "",
      };
      this.$store.commit('addToCurrentSaleLines', obj);
      this.$store.dispatch('calculateTipAmount');
    },

    addToSaleWithPhoneNumber(item) {
      let obj = {
        Plu: item.PLU,
        Description: item.Description,
        Qty: 1,
        Unit: item.SellingIncl,
        Line: item.SellingIncl,
        tourismLevy : 0,
        Discount: 0,
        vat: 0,
        instructions: [],
        voucher: true,
        offer: item.offer || "",
        cell: item.cell || "", // Add phoneNumber or any other required data
      };
      this.$store.commit('addToCurrentSaleLines', obj);
      this.$store.dispatch('calculateTipAmount');
    },
  },
};
</script>


<style lang="scss" scoped>
.sales-panel {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;

  .sale-header {
    background: #F2F2F4;
    box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.4);
    border: 1px solid #E6E7EC;
    height: 53px;
    max-height: 55px;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2 {
        margin: 0;
      }

      button {
        width: 30px;
        background: white;
        font-size: 25px;
      }
    }
  }

  @media (min-height: 769px) {
    .sale-lines-table {
      height: 83vh !important;
    }
  }

  .sale-lines-table {
    flex: auto;
    overflow-y: auto;
    height: 77vh;

    .sales-list {
      list-style: none;
      padding: 0 0 130px 0;

      li {
        border: 1px solid #E6E7EC;
      }
    }
  }

  .sale-stats {
    position: absolute;
    background: #F2F2F4;
    border: 1px solid #E6E7EC;
    max-height: 163px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.list-group-item {
  font-size: 1.5rem; /* Increased font size */
  padding: 15px 20px; /* Increased padding */
}

h4, h5, h6 {
  margin: 0;
}

.example-card-img-responsive {
  width: 80px;
  height: 65px;
  margin: 10px;
  opacity: 0.8;
}

.product-card {
  height: 110px;
  transition: 200ms ease-in-out;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;

  .product-description {
    font-weight: 600;
    color: #303030;
  }

  .product-description2 {
    font-weight: 400;
    color: #303030;
  }

  .product-price {
    font-weight: 700;
    color: #322f2f;
  }

  &:hover, &:focus {
    background: #0092f7;
    color: white;
    transform: scale(1.03);

    .product-description {
      color: white;
    }

    .product-price {
      color: white;
    }
  }
}

img {
  height: 94px;
  width: 94px;
  opacity: 0.7;
  border-radius: 10px;
}

.text-sm {
  font-size: 12px !important;
}
</style>
