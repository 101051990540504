<template>
  <div class="container-fluid current-sale-line">
    <span class="text-secondary text-sm">Stock Code : {{ saleLine.Plu }}</span>
    <div class="row" @dblclick="openItem">
      <div class="line-description-pane col-7">
        <h6 class="line-description">
          {{ $utils.toPascalCase($utils.cutLength(saleLine.Description, 30)) }}<br>
        </h6>

        <button type="button" id="discount-btn" class="btn btn-outline-info text-sm mx-2 mb-2" @click="discount">
          Add Discount
          <font-awesome-icon icon="percent"/>
        </button>

        <button type="button" style="display: none" data-bs-toggle="modal" :id="`discountCheck-${saleLine.index}`"
                :data-bs-target="`#discountModal-${saleLine.index}`"></button>
        <div class="modal fade" :id="`discountModal-${saleLine.index}`" tabindex="-1"
             :aria-labelledby="`discountModal-${saleLine.index}Label`" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" :id="`discountModal-${saleLine.index}Label`">
                  Apply Discount to {{ $utils.toPascalCase($utils.cutLength(saleLine.Description, 30)) }}
                </h5>
                <button type="button" class="btn-close" :id="`closeBTN-${saleLine.index}`" data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
              <form>
                <div class="d-flex flex-row p-1 justify-content-evenly mt-2">
                  <label>
                    <input class="form-check-input" type="radio" value="value" v-model="discountType">
                    Value Discount
                  </label>
                  <label>
                    <input class="form-check-input" type="radio" value="percent" v-model="discountType">
                    Percentage Discount
                  </label>
                </div>
                <hr class="mx-2"/>
                <label class="row mx-3 mb-2">
                  Discount Amount
                  <input type="number" class="form-control" v-model="discountAmount">
                </label>
              </form>
              <div class="modal-footer mt-3">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Close</a>
                <a class="btn btn-primary" @click="applyDiscount">Save changes</a>
              </div>
            </div>
          </div>
        </div>

        <button @click="voidItem(saleLine)">
          <font-awesome-icon icon="trash" style="color: red"/>
        </button>
        <h5 class="text-success text-sm " v-if="saleLine.Discount>0">{{
            `Discount ${$utils.formatMoney(saleLine.Discount)}`
          }}</h5>
        <h5 class="text-success text-sm " v-if="saleLine.tourismLevy>0">{{
            `T.L ${$utils.formatMoney(saleLine.tourismLevy * saleLine.Qty)}`
          }}</h5>
      </div>
      <div class="d-flex flex-column justify-content-between line-prices-pane col-5">
        <CurrentSaleQty :saleLine="saleLine"/>
        <div class="price-row mb-3 gap-2">
          <p class="price-label">Item Price: <span
              class="line-price">{{ $utils.formatMoney(saleLine.Unit, 2, '.', ',', false) }}</span></p>
          <p class="price-label">Total: <span
              class="line-price">{{
              $utils.formatMoney(( (saleLine.Unit + saleLine.tourismLevy) * saleLine.Qty) - saleLine.Discount + instructionTotal, 2, '.', ',', false)
            }}</span>
          </p>
        </div>

        <div class="d-flex flex-column" v-if="saleCurrency && saleCurrency.Symbol && !(saleCurrency.Rate===1)">
          <span class="text-primary text-sm">Values in ({{ saleCurrency.Symbol }})</span>
          <div class="price-row gap-2">
            <p class="price-label">Item Price: <span
                class="line-price">{{
                $utils.formatMoney(saleLine.Unit / saleCurrency.Rate, 2, '.', ',', false)
              }}</span></p>
            <p class="price-label">Total: <span
                class="line-price">{{
                $utils.formatMoney(((saleLine.Unit * saleLine.Qty) - saleLine.Discount + instructionTotal) / saleCurrency.Rate, 2, '.', ',', false)
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <ul class="cooking-instruction-list" v-if="hasInstructions">
        <li class="cooking-instruction-list-item" v-for="instruction in instructions">
          {{ instruction.CookingMsg }} <span>{{ instruction.count }} X {{
            $utils.formatMoney(instruction.Price)
          }}</span>
          Total: {{ $utils.formatMoney(instruction.count * instruction.Price) }}
        </li>
      </ul>
    </div>

    <custom-modal :ref="`saleline-item-`+saleLine.index" size="md"
                  :title="`Update item Price ${$utils.toPascalCase(saleLine.Description)}`">
      <div class="d-flex flex-column">
        <label for="itemPrice">Unit Price</label>
        <input type="number" step="0.01" id="itemPrice" @input="updateLinePrice"
               class="form-control mb-2"
               v-model.number="saleLine.Unit">

        <label :for="`price-type-${saleLine.index}`" class="mb-3" v-if="canSetPriceLevel">
          Item Price
          <select :id="`price-type-${saleLine.index}`" class="w-100 form-control" v-model="saleLine.priceType"
                  @change="recalculateSaleLine">
            <option value="Normal">Normal</option>
            <option value="At Cost">At Cost</option>
            <option value="Price 1">Price 1</option>
            <option value="Price 2">Price 2</option>
            <option value="Price 3">Price 3</option>
            <option value="Price 4">Price 4</option>
            <option value="Price 5">Price 5</option>
          </select>
        </label>

        <label for="itemQty">Qty</label>
        <input type="number" step="0.001" id="itemQty" @input="$store.commit(`updateSaleLinePrice`, saleLine)"
               class="form-control mb-1" v-model.number="saleLine.Qty">

        <div v-if="saleLine.Discount < 0">
          <span class="text-primary mb-1">Line Before Discount : {{
              $utils.formatMoney(saleLine.Line + saleLine.Discount)
            }}</span>
          <span class="text-success mb-1">Discount : {{ $utils.formatMoney(saleLine.Discount) }}</span>
        </div>

        <label for="itemLinePrice">Line Price</label>
        <input type="number" @input="updateItemPrice" step="0.01" id="itemLinePrice" class="form-control"
               v-model.number="saleLine.Line">
        <div v-if="saleCurrency && !(saleCurrency.Rate===1)">
          <label for="itemSaleLinePrice">Line ($) Price</label>
          <input type="number" @input="updateSaleCurrencyPrice" step="0.001" id="itemSaleLinePrice" class="form-control"
                 v-model.number="currencyLine">
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import CurrentSaleQty from "@/components/point-of-sale/sales/CurrentSaleQty";
import LineSeparator from "@/components/layout/utils/LineSeparator";
import CustomModal from "@/components/layout/utils/CustomModal";

export default {
  name: "CurrentSaleLine",
  async mounted() {
    this.canSetPriceLevel = await this.$store.dispatch(`validateSecurityPermissionNoPrompt`, `PriceLevel`);
  },
  components: {CustomModal, LineSeparator, CurrentSaleQty},
  data() {
    return {
      discountType: 'value',
      discountAmount: 0.00,
      canSetPriceLevel: false,
      currencyLine : 0.0
    }
  },
  props: {
    saleLine: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasInstructions() {
      return (this.saleLine.instructions && this.saleLine.instructions.length > 0)
    },
    saleCurrency() {
      return this.$store.getters.getSaleCurrency;
    },
    instructionTotal() {
      if (!this.saleLine.instructions || this.saleLine.instructions.length === 0) return 0.00;
      let total = this.saleLine.instructions.map(i => i.Price).reduce((acc, n) => Number(n) + Number(acc))
      if (isNaN(total)) {
        return 0.00;
      }
      return Number(total.toFixed(2))
    },
    instructions() {
      return this.saleLine.instructions.map((int) => {
        int.Price = Number(int.Price.toFixed(2))
        return int;
      });
    }
  },
  methods: {
    updateSaleCurrencyPrice() {
      this.saleLine.Line = this.currencyLine * this.saleCurrency.Rate;
      this.updateItemPrice(false)
    },
    updateItemPrice(updateCurLine = true) {
      this.saleLine.Unit = (this.saleLine.Line + this.saleLine.Discount) / this.saleLine.Qty;
      if (updateCurLine){
        this.currencyLine = this.saleLine.Line / this.saleCurrency.Rate;
      }
    },
    updateLinePrice() {
      this.saleLine.priceType = ``;
      this.recalculateSaleLine();
    },
    recalculateSaleLine() {
      this.$store.commit(`updateSaleLinePrice`, this.saleLine)
    },
    voidItem(item) {
      this.$store.commit('voidLine', item)
    },
    async discount() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, `Discount`)
      if (!isAllowed) return;
      document.getElementById(`discountCheck-${this.saleLine.index}`).click();
    },
    applyDiscount() {
      let type = this.discountType;
      let line = Number(this.saleLine.Unit) * Number(this.saleLine.Qty);
      let discount = Number(this.discountAmount);
      if (type === `percent`) discount = (this.discountAmount / 100) * line;
      this.saleLine.Discount = discount;
      this.saleLine.Line = Number(this.saleLine.Line) - discount;
      document.getElementById(`closeBTN-${this.saleLine.index}`).click();
    },
    async openItem() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermissionNoPrompt`, `PriceChange`)
      if (!isAllowed) return;
      this.saleLine.priceType = `Custom`;
      this.$store.commit(`updateSaleLinePrice`, this.saleLine)
      this.currencyLine = this.saleLine.Line / this.saleCurrency.Rate;
      return this.$refs[`saleline-item-${this.saleLine.index}`].openModal()
    },
  }
}
</script>

<style lang="scss" scoped>
.current-sale-line {
  padding-left: 10px;
  padding-top: 4px;
  min-height: 95px;
  margin-bottom: 5px;

  .row {
    .line-description-pane {
      .line-description {
        font-size: 18px;
        margin: 0;
        line-height: 18px;
        font-weight: 500;
      }
    }

    .line-prices-pane {
      .price-label {
        font-size: 12px;
        margin: 0;

        .line-price {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}

button {
  background-color: transparent;
}

.cooking-instruction-list {
  list-style: inside;

  .cooking-instruction-list-item {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #404040;
    display: flex;
    justify-content: space-between;
  }
}

.price-row {
  display: flex;
  flex-direction: row;
}

.text-sm {
  font-size: 12px;
}

#discount-btn {
  padding: 2px;
  border-radius: 5px;
}
</style>
