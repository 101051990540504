import {Storage} from "@/services/Storage";
import axios from "axios";
import {responses} from "@/utils/responses";

export default {
    name: 'user',
    state: {
        currentUser: undefined,
        securities: [],
        users: [],
        allUsers: [],
    },
    getters: {
        getCurrentUser: (state) => state.currentUser,
        getAllUsers: (state) => state.allUsers,
        getUsers: (state) => state.users,
        getUserByPass: (state) => async (password) => {
            state.users = await Storage.getAllUsers();
            return state.users.find(u => u.Password.toLowerCase() === password.toLowerCase());
        },

        countNonLytSoftUsers: async (state) => {
            state.users = await Storage.getAllUsers();
            return state.users.filter(user => user.Username !== 'Admin').length;
        },

        getUserByID: (state) => async (id) => {
            state.users = await Storage.getAllUsers();
            return state.users.find(u => u.id === id);
        },
        getSecurities: (state) => state.securities,
    },
    mutations: {
        setCurrentUser: async (state, data) => {
            if (!data) {
                data = await Storage.getCurrentUser();
            }
            state.currentUser = data;
        },
        setAllSecurities: async (state, data) => {
            if (!data) data = await Storage.getAllSecurities();
            else await Storage.setAllSecurities(data);
            state.securities = data;
        },
        setAllUsers: async (state, data) => {
            if (data) {
                await Storage.setAllUsers(data)
            } else {
                data = await Storage.getAllUsers();
            }
            state.users = data
        },
    },
    actions: {
        async recordlogin(context , username) {
            let device = await Storage.getDeviceSettings();
            device = device.PC;
            let store = await Storage.getDefaultStore();
            if (!store) return;
            const payload = {
                username,
                platform: 'RetailPOS',
                pc: device,
            }
            return axios.post(serverApi + `supervisorOverrides/${store.StoreDB}/recordLogin`,payload)
        },

        async recordLogout(context , username) {
            let device = await Storage.getDeviceSettings();
            device = device.PC;
            let store = await Storage.getDefaultStore();
            if (!store) return;
            const payload = {
                username: username,
                platform: 'RetailPOS',
                pc: device,
            }
            return axios.post(serverApi + `supervisorOverrides/${store.StoreDB}/recordLogout`,payload)
        },

        getAllUsers: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `employee/${store.StoreDB}/getAll`)
                .then(({data}) => {
                    context.commit('setAllUsers', data.filter(user => !user.archived));
                    return data;
                }).catch((err) => errorHandler.tomcatError(err))
        },

        posLogin: async (context, data) => {
            let currentUser = context.getters.getUsers.find(user => user.Password.toLowerCase() === data.toLowerCase());
            if (currentUser) {
                await Storage.setCurrentUser(currentUser);
                context.commit(`setCurrentUser`, currentUser);
                context.dispatch(`getAllStock`);
                const username = currentUser.Username;
                return {isValid : true, username}
            }
            return {isValid : false, username : null }
        },

        async editEmployee(context, payload) {
            let store = await Storage.getDefaultStore();
            return axios.put(franchiseTomcatApi + `employee/${store.StoreDB}/${payload.id}`, payload)
                .then(() => context.dispatch(`getAllUsers`))
                .catch((err) => errorHandler.tomcatError(err))
        },

        createEmployee: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `employee/${store.StoreDB}`, payload)
                .then(() => context.dispatch('getAllUsers'))
                .catch((err) => errorHandler.tomcatError(err))
        },

        forceCashUp: async () => {
            let store = await Storage.getDefaultStore();
            let currentUser = await Storage.getCurrentUser()
            let settings = await Storage.getDeviceSettings()
            return axios.get(franchiseTomcatApi + `cashup/${store.StoreDB}/forceCashup?user=` + currentUser.Username + '&pc=' + settings.PC)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        async generateStoreOTP() {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `stores/${store.StoreDB}/generateOTP`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        validateSecurityPermissionNoPrompt: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            return group[security];
        },

        validateSecurityPermission: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            console.log(group)
            if (!group[security]) {
                $utils.hideLoading();
                return context.dispatch('checkUserSecurity', security).then(async (result) => {
                    if (result.isConfirmed) {
                        let value = result.value;
                        if (value.allowed) {
                            let store = await Storage.getDefaultStore();
                            axios.post(serverApi + `supervisorOverrides/${store.StoreDB}`, {
                                Supervisor: value.supervisor,
                                Cashier: value.cashier,
                                Reason: security,
                            })
                        }
                        return value.allowed;
                    }
                    return false;
                });

            }
            if (security.toString().toLowerCase().includes(`void`)) {
                return {supervisor: user.Username}
            }
            return true;
        },

        async getAllSecurities(context) {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `empsecurity/${store.StoreDB}/getAll`)
                .then(({data}) => context.commit(`setAllSecurities`, data))
                .catch((err) => errorHandler.tomcatError(err))
        },

    }
}
