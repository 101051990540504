export default {
    name : `vat_calculator`,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getVatTotal({ dispatch , getters }, { a, incl, TaxLabels, mtvAmount }) {
            let vat = 0;
            if (TaxLabels === 'A') {
                vat = dispatch.getVatAmount({ dispatch , getters },{a, incl, TaxLabels});
            } else if (TaxLabels === 'B') {
                vat = dispatch.getMTVAmount({ dispatch , getters }, {vat, qty:1, incl, mtvAmount});
            } else {
                vat = 0;
            }
            return vat;
        },
        getVatAmount({ dispatch , getters }, { a, incl, TaxLabels }) {
            const vatRate = getters.getZraCategoryByLabel(TaxLabels).TaxRate;
            const rate = vatRate + 1;
            let b = 0;
            if (incl) {
                b = a - (a / rate);
            } else {
                b = (a * rate) - a;
            }
            return b;
        },
        getMTVAmount({ dispatch , getters }, { a, qty, incl, mtvAmount }) {
            a = a / qty;
            const cat = getters.getZraCategoryByLabel('B');
            if (!incl && cat && cat.TaxLabel.length > 0) {
                a = a * (cat.TaxRate + 1);
            }
            let b = mtvAmount;
            if (cat && cat.TaxLabel.length > 0) {
                const rate = cat.TaxRate + 1;
                if (incl) {
                    if (a > b) {
                        a = a - (a / rate);
                        return a;
                    } else {
                        b = b - (b / rate);
                        return b * qty;
                    }
                } else {
                    b = b / rate;
                    if (a > b) {
                        a = (a * rate) - a;
                        return a * qty;
                    } else {
                        b = (b * rate) - b;
                        return b * qty;
                    }
                }
            } else {
                return 0;
            }
        },
        getTourismLevyAmount({ getters }, { amount, incl }) {
            if (incl) {
                const cat = getters.getZraCategoryByLabel('A');
                if (cat && cat.TaxLabel.length > 0) {
                    amount = amount / (cat.TaxRate + 1);
                }
            }
            const cat = getters.getZraCategoryByLabel('T');
            if (cat && cat.TaxLabel.length > 0) {
                const rate = cat.TaxRate + 1;
                amount = amount - (amount / rate);
                return amount;
            } else {
                return 0;
            }
        },
        getInsuranceAmount({ getters }, { amount, incl }) {
            if (incl) {
                const cat = getters.getZraCategoryByLabel('A');
                if (cat && cat.TaxLabel.length > 0) {
                    amount = amount / (cat.TaxRate + 1);
                }
            }
            const cat = getters.getZraCategoryByLabel('I');
            if (cat && cat.TaxLabel.length > 0) {
                const rate = cat.TaxRate + 1;
                amount = amount - (amount / rate);
                return amount;
            } else {
                return 0;
            }
        }
    }
};