<template>
  <div>
    <loading :active.sync="loading"
             :can-cancel="false"
             :is-full-page="true"></loading>

    <div class="method-sheet">
      <div class="header">
        <h4><b> Select Payment Method </b></h4>
      </div>

      <div class="row">
        <div class="col">
          <div class="payment-method-btn">
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="processTransaction('Cash')">
              <font-awesome-icon icon="fa-solid fa-money-bills"/>
              Cash
            </button>
          </div>
        </div>
        <div class="col">
          <div class="payment-method-btn">
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="processTransaction('Card')">
              <font-awesome-icon icon="fa-solid fa-credit-card"/>
              Card
            </button>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <div class="payment-method-btn">
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="goToSplitScreen">
              <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left"/>
              Split
            </button>
          </div>
        </div>
        <div class="col">
          <div class="payment-method-btn" v-if="!saleDetails.refund">
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="kazangPayment">
              <img height="25px" src="@/assets/img/Kazang.png" alt="Kazang">
              Kazang
            </button>
          </div>
        </div>
      </div>

      <div class="row">
<!--        <div class="col-6">-->
<!--          <div class="payment-method-btn" v-if="!saleDetails.refund">-->
<!--            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="spennPayment">-->
<!--              <img height="35px" src="@/assets/img/SpennLogo.png" alt="Spenn">-->
<!--              Spenn-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

        <div class="col-6" v-if="customer">
          <div class="payment-method-btn" >
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="processTransaction('AccountSale')">
              <font-awesome-icon icon="fa-solid fa-address-card"/>
              Account
            </button>
          </div>
        </div>

        <div class="col-6" v-if="isValidString(loyaltyDetails.barcode)">
          <div class="payment-method-btn" >
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="processTransaction('Loyalty Program')">
              <font-awesome-icon icon="heart-circle-check" style="height: 20px"/>
              Loyalty
            </button>
          </div>
        </div>
      </div>
      <line-separator title="Additional Methods" class="mb-1"/>
      <div class="row">
        <div class="col-6" v-for="method in paymentMethods">
          <div class="payment-method-btn">
            <ForeignCurrency :row-data="method" v-if="!method.IsBaseCurrency" @click="processTransaction(method.name)"/>
            <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="processTransaction(method.name)" v-else>
              {{ method.name }} ({{ method.CurrencySymbol }})
            </button>
          </div>
        </div>
      </div>


      <div class="payment-method-btn" v-if="!saleDetails.refund && deviceSettings.layby">
        <button class="lyt-soft-btn" :disabled="isAmountTooLow" @click="createLayBySales">
          <font-awesome-icon icon="truck" style="height: 20px"/>
          Sell on Lay-By
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ForeignCurrency from "@/components/point-of-sale/sales/payment/ForeignCurrency";
import LineSeparator from "@/components/layout/utils/LineSeparator.vue";

export default {
  name: "DesktopPaymentMethods",
  components: {LineSeparator, ForeignCurrency},
  props: {
    minimumAmount: {
      required: true
    },
    // setSwipe: {type: Function, required: true}
    // reference: { type: String, required: true },
    // total: { type: Number, required: true },
    // paid: { type: Number, required: true },
    // change: { type: Number, required: true },
  },
  computed: {
    currentSaleLines() {
      return this.$store.getters.getSaleLines
    },
    isAmountTooLow() {
      if (this.currentSaleLines.length === 0) return true;
      if (this.minimumAmount === "") return false;
      if(this.saleTotal > Number(this.minimumAmount)) {
        return this.saleTotal > Number(this.minimumAmount);
      }
      if(this.saleTotal === Number(this.minimumAmount)){
        return !(this.saleTotal === Number(this.minimumAmount));
      }
    },
    saleDetails() {
      return this.$store.getters.getSaleDetails
    },
    loyaltyDetails() {
      return this.$store.getters.getLoyaltyDetails;
    },
    customer() {
      let customer = this.$store.getters.getCustomer;
      console.log({customer})
      return customer;
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount
    },
    deviceSettings() {
      return this.$store.getters.getDeviceSettings;
    },
    discount() {
      return this.$store.getters.getSaleDiscount;
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods
          .filter(method => method.active);
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    createLayBySales() {
      return this.$router.push({name: `lay-by-payment`});
    },
    setPropertyValue(property, value) {
      this[property] = value;
    },
    isValidString(string) {
      return isValidString(string);
    },
    processTransaction(type) {
      this.$emit(`processPayment`,type);
    },
    async openCashDrawer() {
      return this.$store.dispatch(`openCashDrawer`)
    },
    goToSplitScreen() {
      let obj = this.saleDetails;
      obj.type = 'Split';
      this.$store.commit('setSaleDetails', obj);
      return this.$router.push({name: 'split-payment'})
    },

    kazangPayment() {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          return this.$store.dispatch('firstKazangPaymentStep', input)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },

    confirmKazangPayment(firstKazangStepResponse) {
      if (firstKazangStepResponse === "processed") {
        return this.$router.push({name: 'viewPayment'})
      }
      return Swal.fire({
        title: 'Confirm Kazang Payment',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return this.$store.dispatch('secondKazangStep', firstKazangStepResponse)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
              })
              .catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },

    spennPayment() {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          return this.$store.dispatch('firstSpennPaymentStep', input)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.confirmSpennPayment(result.value)
        }
      })
    },

    confirmSpennPayment(firstSpennStepResponse) {
      if (firstSpennStepResponse === "processed") {
        return this.$router.push({name: 'viewPayment'})
      }
      return Swal.fire({
        title: 'Confirm Spenn Payment',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return this.$store.dispatch('secondSpennStep', firstSpennStepResponse)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
              })
              .catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.method-sheet {
  padding: 15px;
}

h4, h6 {
  display: flex;
  justify-content: center;
}

button {
  color: white;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  height: 90%;
  width: 75%;
  max-width: 600px;
}

.payment-method-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
}

.header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding: 10px;
}
</style>