<template>
  <div class="container-fluid  payment-panel">
    <button class="payment-button" :style="saveColor" :disabled="disabled" @click="saveTable">
      SAVE
      <font-awesome-icon icon="fa-solid fa-save"></font-awesome-icon>
    </button>


    <button :disabled="disabled" class="payment-button" @click="process" :style="color">
      PAY
      <span class="w-100">{{ $utils.formatMoney(saleTotal - discount + tipsTotal, 2, '.', ',', false) }}</span>
    </button>
  </div>
</template>

<script>

export default {
  name: "PaymentControls",
  computed: {
    sale() {
      return this.$store.getters.getCurrentSale;
    },
    discount() {
      return this.$store.getters.getSaleDiscount.total;
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal
    },
    tipsTotal() {
      return this.$store.getters.getTipAmount;
    },
    disabled() {
      return this.saleTotal === 0;
    },
    color() {
      return this.disabled ? {background: 'grey'} : {background: '#9ccc65'}
    },

    delivery() {
      return this.disabled ? {background: 'grey'} : {background: '#37b1c6'}
    },

    saveColor() {
      return this.disabled ? {background: 'grey'} : {background: '#475f9b'}
    }
  },
  methods: {
    saveTable() {
      let table = this.$store.getters.getTableBySaleNum(this.sale);

      if (table) {
        return this.editTable(table);
      } else {
        return this.createTable();
      }
    },
    loadPanel() {
      return Swal.fire({
        html: 'Updating table....',
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        },
      });
    },
    editTable() {
      let settings = this.$store.getters.getDeviceSettings;
      let table = {};
      this.loadPanel()
      table.items = this.$store.getters.getSaleLines;
      table.Customer = this.$store.getters.getCustomer;
      table.name = this.$store.getters.getOpenTableName;
      return this.$store.dispatch('updateTable', table)
          .then(() => {
            responses.showInfo('Table successfully updated')
            let autoLogout = settings.AutoLog === 1 || settings.PosType === `WaiterStation`;
            if (autoLogout) {
              return this.$router.push({ name : `poslogin` });
            }
            return this.$router.push({ name : `tables` })
          }).finally(() => Swal.close())
    },
    createTable() {
      this.$store.commit('setIsTabOpen', true)
      return Swal.fire({
        title: 'Quotation Name',
        icon: 'question',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Save Quotation',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Quotation name is required`)
          this.$store.commit(`setOpenTableName` , input)
          return this.$store.dispatch('saveNewTable', {
            name : input,
            Customer: this.$store.getters.getCustomer,
          }).then(() => input)
              .catch(error => Swal.showValidationMessage(error))
        },
      }).then((result) => {
        this.$store.commit('setIsTabOpen',false)
        if (result.isConfirmed) {
          return Swal.fire({
            title: `Success`,
            icon: 'success',
            text: `Quotation ${result.value} Saved`
          });
        }
      })
    },

    process() {
      return this.$router.push({name: 'payment'})
    },
    processDelivery() {
      this.$store.commit(`setTipAmount` , 0)
      return this.process();
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .view-order-button {
    opacity: 1 !important;
  }
}

.payment-panel {
  -webkit-box-shadow: 0 -2px 8px 0 rgba(203, 153, 126, 0.4);
  box-shadow: 0 -2px 8px 0 rgba(203, 153, 126, 0.4);
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: row;
  padding: 10px;
  z-index: 1;
  background: white;

  .view-order-button {
    color: white;
    background: #40434A;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    min-width: 140px;
    opacity: 0;
  }

  .payment-button {
    color: white;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    border-radius: 5px;
    height: 100%;
    width: 60%;
    max-width: 400px;
  }
}
</style>
